import React from 'react';

import Image from '../../components/Image/Image';
import TitleUnderlineParagraph from '../../components/TitleUnderlineParagraph/TitleUnderlineParagraph';
import * as styles from './Partners.module.scss';
import IPartners from './Partners.type';

const Partners = (props: IPartners) => (
    <section className={styles.section}>
        <div className="container">
            <TitleUnderlineParagraph
                className={styles.title}
                heading={props.heading}
                headingLevel="h2"
                text={props.description}
                textSize="medium"
            />
            <ul className={styles.partnerLogos}>
                {props.partnerLogos.map((logo) => (
                    <Image key={logo.localFile.publicURL} {...logo} className={styles.logo} />
                ))}
            </ul>
        </div>
    </section>
);

export default Partners;
